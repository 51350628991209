import FavouriteMoviesContextProvider from './contexts/FavouriteMoviesContext'
import AppRouter from './routes/AppRouter'

function App() {
	return (
		<FavouriteMoviesContextProvider>
			<AppRouter />
		</FavouriteMoviesContextProvider>
	)
}

export default App
