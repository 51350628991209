import MyCarousel from '../components/MyCarousel'

const trending_movie_api = `${process.env.REACT_APP_THE_MOVIEDB_BASE_URL}/trending/movie/day?api_key=${process.env.REACT_APP_THE_MOVIEDB_API_KEY}`
const top_rated_movie_api = `${process.env.REACT_APP_THE_MOVIEDB_BASE_URL}/movie/top_rated?api_key=${process.env.REACT_APP_THE_MOVIEDB_API_KEY}`
const now_playing_movie_api = `${process.env.REACT_APP_THE_MOVIEDB_BASE_URL}/movie/now_playing?api_key=${process.env.REACT_APP_THE_MOVIEDB_API_KEY}`
const upcoming_movie_api = `${process.env.REACT_APP_THE_MOVIEDB_BASE_URL}/movie/upcoming?api_key=${process.env.REACT_APP_THE_MOVIEDB_API_KEY}`

const Home = () => {
	return (
		<div className="container home">
			<MyCarousel title="Now playing Movies" url={now_playing_movie_api} />
			<MyCarousel title="Trending Movies" url={trending_movie_api} />
			<MyCarousel title="Top Rated Movies" url={top_rated_movie_api} />
			<MyCarousel title="Upcoming Movies" url={upcoming_movie_api} />
		</div>
	)
}

export default Home
