import { createContext, useReducer, useEffect } from 'react'
import { favouriteMoviesReducer } from '../reducers/favouriteMoviesReducer'

export const FavouriteMoviesContext = createContext()

const init = () => {
	const localData = localStorage.getItem('favouriteMovies')
	return localData ? JSON.parse(localData) : []
}

const FavouriteMoviesContextProvider = ({ children }) => {
	const [favouriteMovies, dispatch] = useReducer(
		favouriteMoviesReducer,
		[],
		init
	)

	useEffect(() => {
		localStorage.setItem('favouriteMovies', JSON.stringify(favouriteMovies))
	}, [favouriteMovies])

	return (
		<FavouriteMoviesContext.Provider value={{ favouriteMovies, dispatch }}>
			{children}
		</FavouriteMoviesContext.Provider>
	)
}

export default FavouriteMoviesContextProvider
