import { useEffect, useState, useContext } from 'react'
import addToFavouriteIcon from './../img/addToFavorite.svg'
import deleteIcon from './../img/delete.svg'
import playIcon from './../img/play.svg'
import { getMonthName, getFormattedRuntime } from '../utils/helper'
import { FavouriteMoviesContext } from '../contexts/FavouriteMoviesContext'

const image_base_url = 'https://image.tmdb.org/t/p/w342'

const MovieDetailsCard = ({
	movieId,
	posterPath,
	title,
	releaseDate,
	genres,
	overview,
	runtime,
	showTrailer
}) => {
	const { favouriteMovies, dispatch } = useContext(FavouriteMoviesContext)
	const [formattedReleaseDate, setFormattedreleaseDate] = useState('unknown')
	const [formattedRuntime, setFormattedRuntime] = useState('0min')
	const [isFavouriteMovie, setIsFavouriteMovie] = useState(false)

	useEffect(() => {
		if (releaseDate) {
			let dateObj = new Date(releaseDate)
			let date = dateObj.getDate()
			let month = getMonthName(dateObj.getMonth() + 1)
			let year = dateObj.getFullYear()
			setFormattedreleaseDate(`${date} ${month} ${year}`)
		}
		if (runtime) {
			setFormattedRuntime(getFormattedRuntime(runtime))
		}
	}, [runtime, releaseDate])

	useEffect(() => {
		let isFavourite = false
		for (let movie of favouriteMovies) {
			if (movie.id === movieId) {
				isFavourite = true
				break
			}
		}
		setIsFavouriteMovie(isFavourite)
	}, [favouriteMovies, movieId, setIsFavouriteMovie])

	const addToFavourite = () => {
		dispatch({
			type: 'ADD_MOVIE',
			movie: {
				id: movieId,
				posterPath,
				title
			}
		})
	}

	const removeFromFavourite = () => {
		dispatch({
			type: 'REMOVE_MOVIE',
			id: movieId
		})
	}

	return (
		<div className="movie-details-card">
			{posterPath && (
				<img
					className="poster"
					src={`${image_base_url}${posterPath}`}
					alt={`${title} movie poster`}
				/>
			)}
			<div className="info">
				<div className="info__header">
					<h1 className="heading-2">{title}</h1>
					{isFavouriteMovie ? (
						<img
							src={deleteIcon}
							onClick={removeFromFavourite}
							alt="delete icon"
						/>
					) : (
						<img
							src={addToFavouriteIcon}
							onClick={addToFavourite}
							alt="favourite icon"
						/>
					)}
				</div>
				<div className="details">
					<div className="detail">
						<h3 className="heading-3">Release Date</h3>
						<p className="detail__info">{formattedReleaseDate}</p>
					</div>
					<div className="detail">
						<h3 className="heading-3">Duration</h3>
						<p className="detail__info">{formattedRuntime}</p>
					</div>
					<div className="detail">
						<h3 className="heading-3">Genre</h3>
						<p className="detail__info">{genres.join(', ')}</p>
					</div>
					<div className="play-trailer" onClick={showTrailer}>
						<img src={playIcon} alt="play icon" />
						<h3 className="heading-3">Play Trailer</h3>
					</div>
				</div>
				<div className="overview">
					<h2 className="heading-3">Overview</h2>
					<p>{overview}</p>
				</div>
			</div>
		</div>
	)
}

export default MovieDetailsCard
