import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ErrorMessage from '../components/ErrorMessage'
import Loader from '../components/Loader'
import MovieCast from '../components/MovieCast'
import MovieDetailsCard from '../components/MovieDetailsCard'
import MyCarousel from '../components/MyCarousel'
import Trailer from '../components/Trailer'
import useFetch from '../hooks/useFetch'

const MovieDetails = () => {
	const { id } = useParams()
	const movie_details_api = `${process.env.REACT_APP_THE_MOVIEDB_BASE_URL}/movie/${id}?api_key=${process.env.REACT_APP_THE_MOVIEDB_API_KEY}`
	const similar_movie_api = `${process.env.REACT_APP_THE_MOVIEDB_BASE_URL}/movie/${id}/similar?api_key=${process.env.REACT_APP_THE_MOVIEDB_API_KEY}`

	const { data, isPending, error } = useFetch(movie_details_api)
	const [posterPath, setposterPath] = useState(null)
	const [title, setTitle] = useState('')
	const [releaseDate, setReleaseDate] = useState(null)
	const [genres, setGenres] = useState([])
	const [overview, setOverview] = useState('')
	const [runtime, setRuntime] = useState(0)
	const [showTrailer, setShowTrailer] = useState(false)

	//reset state on route change
	useEffect(() => {
		setShowTrailer(false)
	}, [id])

	useEffect(() => {
		if (data) {
			setposterPath(data?.poster_path)
			setTitle(data?.title || '')
			setReleaseDate(data?.release_date || '')
			setOverview(data?.overview || '')
			setRuntime(data?.runtime || 0)
			if (data.genres) {
				setGenres(data.genres.map((genere) => genere.name))
			}
		}
	}, [data, setposterPath])

	const apiRequestTemplate = () => {
		if (isPending) {
			return <Loader />
		} else if (error) {
			return <ErrorMessage message={error} />
		} else {
			return (
				<MovieDetailsCard
					movieId={id}
					posterPath={posterPath}
					title={title}
					releaseDate={releaseDate}
					genres={genres}
					overview={overview}
					runtime={runtime}
					showTrailer={() => setShowTrailer(true)}
				/>
			)
		}
	}

	return (
		<div className="container movie-details">
			<h1 className="heading-1">Movie Details</h1>
			<div className="movie-details__content">{apiRequestTemplate()}</div>
			<MovieCast movieId={id} />
			<MyCarousel title="Similar Movies" url={similar_movie_api} />
			{showTrailer && (
				<Trailer movieId={id} closeTrailer={() => setShowTrailer(false)} />
			)}
		</div>
	)
}

export default MovieDetails
