import loader from '../img/loader.svg'

const Loader = () => {
	return (
		<div className="loader">
			<img src={loader} alt="loader" />
		</div>
	)
}

export default Loader
