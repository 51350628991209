import { useEffect, useState } from 'react'
import logo from '../img/logo.svg'
import searchLogo from '../img/search.svg'
import dark from '../img/dark.svg'
import light from '../img/light.svg'
import favorite from '../img/favorite.svg'
import { useHistory, Link } from 'react-router-dom'

const Header = () => {
	let history = useHistory()

	const [search, setSearch] = useState('')
	const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')

	const handleSearch = (e) => {
		e.preventDefault()
		if (search.trim().length > 0) {
			history.push(`/search/${search.trim()}`)
		}
	}

	const toggleTheme = (e) => {
		let newTheme = theme === 'light' ? 'dark' : 'light'
		setTheme(newTheme)
		//store the value of theme in local storage for future visits
		localStorage.setItem('theme', newTheme)
	}

	useEffect(() => {
		document.body.dataset.theme = theme
	}, [theme])

	return (
		<header className="header">
			<div className="container nav">
				<Link to="/" className="nav__info">
					<img className="nav__info__logo" src={logo} alt="app logo" />
					<h3 className="nav__info__title">React Movies</h3>
				</Link>
				<form className="nav__searchbar" onSubmit={handleSearch}>
					<input
						className="nav__searchbar__input"
						type="search"
						placeholder="search movies"
						onChange={(e) => setSearch(e.target.value)}
					/>
					<button className="nav__searchbar__btn">
						<img className="search-logo" src={searchLogo} alt="search logo" />
					</button>
				</form>
				<div className="nav__options">
					<div className="theme-btn" onClick={toggleTheme}>
						{theme === 'dark' ? (
							<img src={dark} alt="dark logo" />
						) : (
							<img src={light} alt="light logo" />
						)}
					</div>
					<Link to="/favourites">
						<img
							className="favourite-btn"
							src={favorite}
							alt="favourite logo"
						/>
					</Link>
				</div>
			</div>
		</header>
	)
}

export default Header
