import { useContext } from 'react'
import { FavouriteMoviesContext } from '../contexts/FavouriteMoviesContext'
import MoviePoster from '../components/MoviePoster'

const FavouriteMovies = () => {
	const { favouriteMovies } = useContext(FavouriteMoviesContext)

	return (
		<div className="container favourite-movies">
			<h1 className="heading-1">Favourite Movies</h1>
			{favouriteMovies.length === 0 ? (
				<div className="zero-result">No movie found</div>
			) : (
				<div className="favourite-movies__content">
					{favouriteMovies.map((movie) => (
						<MoviePoster
							showDeleteBtn={true}
							key={movie.id}
							id={movie.id}
							posterPath={movie.posterPath}
							title={movie.title}
						/>
					))}
				</div>
			)}
		</div>
	)
}

export default FavouriteMovies
