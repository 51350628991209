import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { FavouriteMoviesContext } from '../contexts/FavouriteMoviesContext'
import deleteIcon from './../img/delete.svg'

const image_base_url = 'https://image.tmdb.org/t/p/w185'

const MoviePoster = ({ id, posterPath, title, showDeleteBtn }) => {
	const history = useHistory()
	const { dispatch } = useContext(FavouriteMoviesContext)

	const goToDetailsPage = () => {
		history.push(`/movie/details/${id}`)
	}

	const removeFromFavourite = (e) => {
		console.log(e)
		e.stopPropagation()
		dispatch({
			type: 'REMOVE_MOVIE',
			id
		})
	}

	const template =
		posterPath !== null ? (
			<img src={`${image_base_url}${posterPath}`} alt={`${title} poster`} />
		) : (
			<span>{title}</span>
		)
	return (
		<div className="movie-poster" onClick={goToDetailsPage}>
			{template}
			<div className="movie-poster-overlay">
				{showDeleteBtn ? (
					<img
						className="movie-poster-overlay__delete__btn"
						src={deleteIcon}
						onClick={removeFromFavourite}
						alt="delete icon"
					/>
				) : (
					<span></span>
				)}
				<span className="movie-poster-overlay__title">{title}</span>
			</div>
		</div>
	)
}

MoviePoster.defaultProps = {
	showDeleteBtn: false
}

export default MoviePoster
