import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

//scroll to top on page refresh
window.onbeforeunload = function () {
	window.scrollTo(0, 0)
}

const ScrollToTop = () => {
	const { pathname } = useLocation()
	useEffect(() => {
		/* settimeout make sure this run after components have rendered. This will help fixing bug for some views where scroll to top not working perfectly */
		setTimeout(() => {
			window.scrollTo({ top: 0, behavior: 'smooth' })
		}, 0)
	}, [pathname])
	return null
}

export default ScrollToTop
