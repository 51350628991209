import { useState, useEffect } from 'react'
import useFetch from '../hooks/useFetch'
import Loader from './Loader'
import ErrorMessage from './ErrorMessage'

const image_base_url = 'https://image.tmdb.org/t/p/w342'

const MovieCast = ({ movieId }) => {
	const [cast, setCast] = useState([])

	const movie_cast_api = `${process.env.REACT_APP_THE_MOVIEDB_BASE_URL}/movie/${movieId}/credits?api_key=${process.env.REACT_APP_THE_MOVIEDB_API_KEY}`
	const { data, isPending, error } = useFetch(movie_cast_api)

	useEffect(() => {
		if (data.cast) {
			setCast(data.cast.filter((actor) => actor.profile_path != null))
		}
	}, [data])

	const apiRequestTemplate = (cast) => {
		if (isPending) {
			return <Loader />
		} else if (error) {
			return <ErrorMessage message={error} />
		} else if (cast.length > 0) {
			return cast.map((actor) => (
				<div key={actor.id} className="movie-cast__card">
					<div className="movie-cast__card__content">
						<img
							src={`${image_base_url}${actor.profile_path}`}
							alt={`${actor.name} avatar`}
						/>
						<span>{actor.name}</span>
					</div>
				</div>
			))
		} else {
			return <div className="zero-result">No cast found</div>
		}
	}

	return (
		<div className="movie-cast">
			<h1 className="heading-1">Movie Cast</h1>
			<div className="movie-cast__content">{apiRequestTemplate(cast)}</div>
		</div>
	)
}

export default MovieCast
