import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Header from '../components/Header'
import ScrollToTop from '../components/ScrollToTop'
import FavouriteMovies from '../views/FavouriteMovies'
import Home from '../views/Home'
import MovieDetails from '../views/MovieDetails'
import PageNotFound from '../views/PageNotFound'
import SearchResult from '../views/SearchResult'

const AppRouter = () => {
	return (
		<Router>
			<ScrollToTop />
			<div className="app">
				<Header />
				<div className="app__content">
					<Switch>
						<Route exact path="/">
							<Home />
						</Route>
						<Route
							path="/search/:query"
							/* The key will force re-rendering of page when query parameter changes. */
							render={(props) => (
								<SearchResult key={props.match.params.query} {...props} />
							)}></Route>
						<Route path="/movie/details/:id">
							<MovieDetails />
						</Route>
						<Route path="/favourites">
							<FavouriteMovies />
						</Route>
						<Route path="*">
							<PageNotFound />
						</Route>
					</Switch>
				</div>
			</div>
		</Router>
	)
}

export default AppRouter
