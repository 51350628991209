import { useEffect, useState } from 'react'

const useFetch = (url) => {
	const [data, setData] = useState([])
	const [error, setError] = useState('')
	const [isPending, setIsPending] = useState(true)

	useEffect(() => {
		fetchRequest(url)
	}, [url])

	const fetchRequest = async (url) => {
		try {
			setIsPending(true)
			const res = await fetch(url)
			const data = await res.json()
			if (!res.ok) {
				throw new Error(data.status_message)
			} else {
				setData(data)
			}
		} catch (error) {
			console.log(error.message)
			setError(error.message)
		} finally {
			setIsPending(false)
		}
	}

	return { isPending, data, error }
}

export default useFetch
