import { useState, useEffect, useRef, useCallback } from 'react'
import MoviePoster from '../components/MoviePoster'
import { useParams } from 'react-router-dom'
import Loader from '../components/Loader'
import ErrorMessage from '../components/ErrorMessage'

const SearchResult = () => {
	const { query } = useParams()
	const loadMore = useRef(null)

	const [page, setPage] = useState(1)
	const [results, setResults] = useState([])
	const [haveMore, setHaveMore] = useState(true)
	/* totalResults initial value is 1 and not 0 because useEffect will run on start and with inital value 0 it will set haveMore to false */
	const [totalResults, setTotalResults] = useState(1)
	const [error, setError] = useState('')
	const [isPending, setIsPending] = useState(false)

	useEffect(() => {
		//stop fetching more if results equal totalResults
		if (results.length === totalResults) {
			setHaveMore(false)
		}
	}, [results, totalResults])

	const fetchMore = useCallback(async () => {
		if (haveMore && !isPending) {
			const search_movie_api = `${process.env.REACT_APP_THE_MOVIEDB_BASE_URL}/search/movie?api_key=${process.env.REACT_APP_THE_MOVIEDB_API_KEY}&query=${query}&page=${page}`
			try {
				setIsPending(true)
				const res = await fetch(search_movie_api)
				const data = await res.json()
				if (!res.ok) {
					throw new Error(data.status_message)
				} else {
					const new_results = data?.results || []
					setResults([...results, ...new_results])
					setTotalResults(data?.total_results || 0)
					setPage(page + 1)
					setError('')
				}
			} catch (error) {
				setError(error.message)
				setHaveMore(false)
				console.log(error.message)
			} finally {
				setIsPending(false)
			}
		}
	}, [haveMore, page, isPending, results, query])

	useEffect(() => {
		let options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.1
		}

		const target = loadMore.current

		let observer = new IntersectionObserver(async (entries) => {
			if (entries[0].isIntersecting) {
				console.log('intersecting')
				await fetchMore()
			} else {
				console.log('not Intersecting')
			}
		}, options)
		observer.observe(target)

		return () => observer.unobserve(target)
	}, [fetchMore])

	const resultTemplate = () => {
		return (
			<div className="search-results__content">
				{results.map((movie) => (
					<MoviePoster
						key={movie.id}
						id={movie.id}
						posterPath={movie.poster_path}
						title={movie.title}
					/>
				))}
			</div>
		)
	}

	const apiRequestTemplate = () => {
		if (error && results.length === 0) {
			return <ErrorMessage message={error} />
		} else if (!isPending && results.length === 0) {
			return <div className="zero-result">No movie found</div>
		} else {
			if (error) {
				return (
					<>
						{resultTemplate()}{' '}
						<ErrorMessage message={'failed to fetch more movies'} />
					</>
				)
			} else {
				return <>{resultTemplate()}</>
			}
		}
	}

	return (
		<div className="container search-results">
			<h1 className="heading-1">Search Results</h1>
			{apiRequestTemplate()}
			<div ref={loadMore}>{isPending && <Loader />}</div>
		</div>
	)
}

export default SearchResult
