import { useEffect, useState } from 'react'
import useFetch from '../hooks/useFetch'
import YouTube from 'react-youtube'
import ErrorMessage from './ErrorMessage'
import Loader from './Loader'

const Trailer = ({ movieId, closeTrailer }) => {
	const movie_video_api = `${process.env.REACT_APP_THE_MOVIEDB_BASE_URL}/movie/${movieId}/videos?api_key=${process.env.REACT_APP_THE_MOVIEDB_API_KEY}`
	const { data, isPending, error } = useFetch(movie_video_api)

	const [youtubeVideoId, setYoutubeVideoId] = useState(null)

	const opts = {
		playerVars: {
			autoplay: 1
		}
	}

	useEffect(() => {
		console.log(data)
		if (data.results) {
			for (let videoData of data.results) {
				if (videoData.type === 'Trailer' && videoData.site === 'YouTube') {
					setYoutubeVideoId(videoData.key)
					break
				}
			}
		}
	}, [data])

	const closeOnBackgroundClick = (e) => {
		if (!e.currentTarget.closest('.trailer-container')) {
			closeTrailer()
		}
	}

	const apiRequestTemplate = () => {
		console.log('youtubeVideoId', youtubeVideoId)
		console.log('isPending', isPending)
		console.log('error', error)
		if (isPending) {
			return <Loader />
		} else if (error) {
			return (
				<div className="trailer-error-container">
					<ErrorMessage message={error} />
				</div>
			)
		} else if (!youtubeVideoId) {
			return (
				<div className="trailer-error-container">
					<ErrorMessage message="No Trailer Found" />
				</div>
			)
		} else {
			return (
				<YouTube
					videoId={youtubeVideoId}
					opts={opts}
					containerClassName={'react-youtube'}
					className={'react-youtube-iframe'}
				/>
			)
		}
	}

	return (
		<div onClick={closeOnBackgroundClick} className="trailer">
			<div className="trailer-container">{apiRequestTemplate()}</div>
		</div>
	)
}

export default Trailer
